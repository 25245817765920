import * as React from "react";
import { Link, graphql } from "gatsby";
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  NavAbout,
  CNewsList,
} from "../../../components/_index";

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  console.log(data.markdownRemark);
  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        data={{
          title: {
            ja: "電子公告",
          },
          img: {
            src: "/assets/images/common/kv.png",
          },
          imgSp: {
            src: "/assets/images/common/kv__sp.png",
          },
        }}
      />
      <CBreadCrumb
        data={{
          parent: [
            {
              label: "三菱地所ホテルズ＆リゾーツについて",
              path: "/about/",
            },
          ],
          current: {
            label: frontmatter?.title.includes("-")
              ? frontmatter?.title.split("-")[0]
              : frontmatter?.title,
          },
        }}
      />
      <div className="l_sect02">
        <LWrap>
          <h2 className="c_headingLv2">電子公告</h2>
          <CNewsList
            manual={true}
            data={[
              {
                node: {
                  publishedAt: "2024/6/21",
                  infoHotelstopId: "",
                  title: "第24期（2024年3月期）決算公告",
                  pdf: {
                    url: "/publicnotice/koukoku_202406.pdf",
                  },
                },
              },
              {
                node: {
                  publishedAt: "2023/6/23",
                  infoHotelstopId: "",
                  title: "第23期（2023年3月期）決算公告",
                  pdf: {
                    url: "/publicnotice/koukoku_202303.pdf",
                  },
                },
              },
              {
                node: {
                  publishedAt: "2022/6/24",
                  infoHotelstopId: "",
                  title: "第22期（2022年3月期）決算公告",
                  pdf: {
                    url: "/publicnotice/m30a06000000qjvf-att/20220624.pdf",
                  },
                },
              },
              {
                node: {
                  publishedAt: "2021/6/25",
                  infoHotelstopId: "",
                  title: "第21期（2021年3月期）決算公告",
                  pdf: {
                    url: "/publicnotice/m30a06000000oy8b-att/20210624.pdf",
                  },
                },
              },
              {
                node: {
                  publishedAt: "2020/6/26",
                  infoHotelstopId: "",
                  title: "第20期（2020年3月期）決算公告",
                  pdf: {
                    url: "/publicnotice/m30a06000000ndk7-att/20200626.pdf",
                  },
                },
              },
              {
                node: {
                  publishedAt: "2019/6/27",
                  infoHotelstopId: "",
                  title: "第19期（2019年3月期）決算公告",
                  pdf: {
                    url: "/publicnotice/m30a06000000lufb-att/201903.pdf",
                  },
                },
              },
              {
                node: {
                  publishedAt: "2018/6/28",
                  infoHotelstopId: "",
                  title: "第18期（2018年3月期）決算公告",
                  pdf: {
                    url: "/publicnotice/m30a06000000ieyx-att/201803.pdf",
                  },
                },
              },
              {
                node: {
                  publishedAt: "2017/6/28",
                  infoHotelstopId: "",
                  title: "第17期（2017年3月期）決算公告",
                  pdf: {
                    url: "/publicnotice/m30a06000000gtqc-att/koukoku_17_201703.pdf",
                  },
                },
              },
            ]}
          />
        </LWrap>
      </div>
      <NavAbout />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
