import * as React from "react";
import { graphql,Link } from "gatsby";

import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CSectTitle,
  CHistoryList,
  LAboutConceptSect,
  LAboutPromiseSect,
  CBannercard,
  CBtnList,
  NavAbout,
  AssetImage,
  Ctopswiper,

} from "../../components/_index";

import { size } from "lodash";
import { setLang } from "../../utils/set-lang";


// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />

      <Ctopswiper
          size="large"
          title_ja="三菱地所ホテルズ＆リゾーツについて"
            data={[
              {
                img: {
                  src: '/assets/images/about/kv.png',
                },
                imgSp: {
                  src: '/assets/images/about/kv__sp.png',
                },
              },
              {
                img: {
                  src: '/assets/images/common/kv.png',
                },
                imgSp: {
                  src: '/assets/images/common/kv__sp.png',
                },
              },
            ]}
          />


      {/* <CJumbotron
        size="large"
        data={{
          title: {
            ja: "三菱地所ホテルズ＆リゾーツについて",
          },
          img: {
            src: "/assets/images/about/kv.png",
          },
          imgSp: {
            src: "/assets/images/about/kv__sp.png",
          },
        }}
      /> */}
      <CBreadCrumb
        data={{
          parent: [],
          current: {
            label: "三菱地所ホテルズ＆リゾーツについて",
          },
        }}
      />
      <section className="l_sect u_bgGray2 l_sect2">
        <LWrap>
          <div>
           
            <figure className="imgWrapper">
              <AssetImage src="/assets/images/about/img_about.png" alt="" loading="lazy" />
            </figure>
            <div className="title_sl2 mt-40 mt-24-sp">
              <div className="icon_left_sl2">VISION<br className="u_pc" /> &<br className="u_pc" /> CREDO</div>
              <div className=" ml-104 mt-24-sp">
                <div className="ft-32 ft-24-sp">幸せのピースになる</div>
                <div className="content_sl2">
                  <p className="mt-40 ft-16 ft-14-sp ft-14-sp mt-24-sp">
                    人生は、まるでジグソーパズルのようです。<br  />
                    華やかな笑顔、ふと感じる幸せ、ひとすじの涙、癒やされるひととき。<br  />
                    ひとつひとつのピースがつながり、かけがえのない人生を彩っています。
                  </p>
                  <p className="mt-40 ft-16 ft-14-sp mt-24-sp">「幸せのピースになる」それが私たちの願いです。</p>
                  <p className="mt-40 ft-16 ft-14-sp mt-24-sp">お客様や共に働く仲間の⽴場で考え、「⼼地よい加減」で寄り添います。<br  />
                    その⼟地を想い「街と、もてなす。」ことで、幸せな時間、⾵景、体験を届けます。<br  />
                    そして、皆さまとともに「幸せのピースになる」ことを⽬指します。</p>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-80 mt-40-sp">
            <figure className="imgWrapper">
              <AssetImage src="/assets/images/about/img_about02.png" alt="" loading="lazy" />
            </figure>
            <div className="title_sl2 mt-40 mt-24-sp">
              <div className="icon_left_sl2">VALUE</div>
              <div className=" ml-104 mt-24-sp">
                <div className="ft-32 ft-24-sp">街と、もてなす。</div>
                <div className="content_sl2">
                  <p className="mt-40 ft-16 ft-14-sp mt-24-sp">
                  人々が集まり、暮らしや営みの中で文化が根づき、<br />
                  それぞれの街の魅力が育まれています。
                  </p>
                  <p className="mt-40 ft-16 ft-14-sp mt-24-sp">私たちは、その土地を想い、地域に寄り添い、地元に愛されることで「街と、もてなす。」を実現します。</p>
                  <p className="mt-40 ft-16 ft-14-sp mt-24-sp">暮らす人たちの憩いの場として、<br />
                  訪れる人たちの旅の拠点として、<br />
                  街を楽しむ刺激と心地よい癒しを届けていきます。</p>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-80 mt-40-sp">
            <figure className="imgWrapper">
              <AssetImage src="/assets/images/about/img_about03.png" alt="" loading="lazy" />
            </figure>
            <div className="title_sl2 mt-40 mt-24-sp">
              <div className="icon_left_sl2">SERVICE <br className="u_pc" />STANDARD</div>
              <div className=" ml-104 mt-24-sp">
                <div className="ft-32 ft-24-sp">心地よい加減</div>
                <div className="content_sl2">
                  <p className="mt-40 ft-16 ft-14-sp mt-24-sp">
                  私たちは、学びを深め、新しいことへ恐れず挑戦し続けます。 <br />
                  プロのホテリエとして、相手の立場に立ち、共に働く仲間と <br  />
                  お客様に寄り添う「心地よい加減」のサービスを提供します。
                  </p>
                 
                </div>
              </div>
            </div>
          </div>
          <div className="mt-100 mt-60-sp">
            <figure className="imgWrapper triangle_about ">
              <AssetImage src="/assets/images/about/img_about04.png" alt="" loading="lazy" />
            </figure>
       
          </div>
        </LWrap>
      </section>

      {/* <section className="l_sect u_bgGray3">
        <LWrap>
          <div id='history'>
          <CSectTitle
            title={{
              ja: <>沿革</>,
              en: "HISTORY",
            }}
          />
          </div>
          <CHistoryList
            data={[
              {
                year: 1989,
                list: [
                  {
                    label: "6月1日",
                    text: <>ロイヤルパークホテル開業</>,
                  },
                  {
                    label: "7月1日",
                    text: <>三菱地所（株）ホテル事業部設立</>,
                  },
                ],
              },
              {
                year: 1993,
                list: [
                  {
                    label: "9月15日",
                    text: (
                      <>
                        横浜ロイヤルパークホテルニッコー開業（2001年
                        横浜ロイヤルパークホテルに名称変更）
                      </>
                    ),
                  },
                ],
              },
              {
                year: 1994,
                list: [
                  {
                    label: "10月1日",
                    text: (
                      <>
                        三菱地所ホテルグループからロイヤルパークホテルズへ、ホテルチェーン名称変更
                      </>
                    ),
                  },
                ],
              },
              {
                year: 1995,
                list: [
                  {
                    label: "4月8日",
                    text: <>仙台ロイヤルパークホテル開業</>,
                  },
                ],
              },
              {
                year: 2000,
                list: [
                  {
                    label: "11月1日",
                    text: (
                      <>
                        ホテル統括会社（株）ロイヤルパークホテルズアンドリゾーツ設立
                      </>
                    ),
                  },
                ],
              },
              {
                year: 2001,
                list: [
                  {
                    label: "4月1日",
                    text: (
                      <>（株）ロイヤルパークホテルズアンドリゾーツ営業開始</>
                    ),
                  },
                ],
              },
              {
                year: 2003,
                list: [
                  {
                    label: "7月1日",
                    text: (
                      <>
                        ロイヤルパーク汐留タワー開業（2020年 ザ
                        ロイヤルパークホテル アイコニック 東京汐留に名称変更）
                      </>
                    ),
                  },
                ],
              },
              {
                year: 2011,
                list: [
                  {
                    label: "7月30日",
                    text: (
                      <>
                        ロイヤルパークホテル ザ 福岡 開業（2018年 ザ
                        ロイヤルパークホテル 福岡 に名称変更）
                      </>
                    ),
                  },
                  {
                    label: "10月8日",
                    text: (
                      <>
                        ロイヤルパークホテル ザ 京都 開業（2018年 ザ
                        ロイヤルパークホテル 京都三条 に名称変更）
                      </>
                    ),
                  },
                ],
              },
              {
                year: 2013,
                list: [
                  {
                    label: "11月1日",
                    text: (
                      <>
                        ロイヤルパークホテル ザ 名古屋 開業（2018年 ザ
                        ロイヤルパーク キャンバス 名古屋 に名称変更）
                      </>
                    ),
                  },
                ],
              },
              {
                year: 2014,
                list: [
                  {
                    label: "9月30日",
                    text: (
                      <>
                        ロイヤルパークホテル ザ 羽田 開業（2018年 ザ
                        ロイヤルパークホテル 東京羽田 に名称変更）
                      </>
                    ),
                  },
                ],
              },
              {
                year: 2018,
                list: [
                  {
                    label: "4月13日",
                    text: <>ザ ロイヤルパークホテル 京都四条 開業（2024年9月 閉館）</>,
                  },
                  {
                    label: "10月5日",
                    text: <>ザ ロイヤルパークホテル 広島リバーサイド 開業</>,
                  },
                ],
              },
              {
                year: 2019,
                list: [
                  {
                    label: "3月20日",
                    text: <>ザ ロイヤルパーク キャンバス 銀座8 開業</>,
                  },
                  {
                    label: "6月7日",
                    text: <>ザ ロイヤルパーク キャンバス 大阪北浜 開業</>,
                  },
                ],
              },
              {
                year: 2020,
                list: [
                  {
                    label: "3月16日",
                    text: (
                      <>ザ ロイヤルパークホテル アイコニック 大阪御堂筋 開業</>
                    ),
                  },
                ],
              },
              {
                year: 2021,
                list: [
                  {
                    label: "1月21日",
                    text: <>ザ ロイヤルパーク キャンバス 神戸三宮 開業</>,
                  },
                  {
                    label: "3⽉12日",
                    text: <>ザ ロイヤルパークホテル 京都梅⼩路 開業</>,
                  },
                  {
                    label: "6⽉6日",
                    text: <>ザ ロイヤルパーク キャンバス 京都二条 開業</>,
                  },
                  {
                    label: "10⽉1日",
                    text: <>ザ ロイヤルパーク キャンバス 札幌大通公園 開業</>,
                  },
                ],
              },
              {
                year: 2022,
                list: [
                  {
                    label: "4⽉1日",
                    text: <>ザ ロイヤルパークホテル アイコニック 京都 開業</>,
                  },
                  {
                    label: "11⽉16日",
                    text: <>ザ ロイヤルパーク キャンバス 銀座コリドー 開業</>,
                  },
                ],
              },
              {
                year: 2023,
                list: [
                  {
                    label: "8⽉4日",
                    text: <>ザ ロイヤルパーク キャンバス 福岡中洲 開業</>,
                  },
                ],
              },
              {
                year: 2024,
                list: [
                  {
                    label: "1⽉1日",
                    text: <>（株）ロイヤルパークホテルズアンドリゾーツから三菱地所ホテルズ＆リゾーツ（株）へ社名変更</>,
                  },
                  {
                    label: "2⽉20日",
                    text: <>ザ ロイヤルパークホテル アイコニック 名古屋 開業</>,
                  },
                  {
                    label: "5⽉30日",
                    text: <>ザ ロイヤルパークホテル 銀座6丁目 開業</>,
                  },
                ],
              },
            ]}
          />
        </LWrap>
      </section> */}
      {/* <section className="l_sect u_bgWhite">
        <LWrap>
          <CSectTitle
            title={{
              ja: <>ブランド一覧</>,
              en: "BRAND",
            }}
          />
          <CBannercard
            data={[
              {
                img: {
                  src: "/assets/images/about/img_brand.png",
                  alt: "仙台ロイヤルパークホテル",
                },
                link: {
                  href: "https://www.srph.co.jp/",
                  blank: true,
                },
              },
              {
                img: {
                  src: "/assets/images/about/img_brand02.png",
                  alt: "ロイヤルパークホテル",
                },
                link: {
                  href: "https://www.rph.co.jp/",
                  blank: true,
                },
              },
              {
                img: {
                  src: "/assets/images/about/img_brand03.png",
                  alt: "横浜ロイヤルパークホテルロゴ",
                },
                link: {
                  href: "https://www.yrph.com/",
                  blank: true,
                },
              },
              {
                img: {
                  src: "/assets/images/about/img_brand04.png",
                  alt: "ICONIC",
                },
                link: {
                  href: "/brand/#iconic",
                },
              },
              {
                img: {
                  src: "/assets/images/about/img_brand05.png",
                  alt: "THE",
                },
                link: {
                  href: "/brand/#the",
                },
              },
              {
                img: {
                  src: "/assets/images/about/img_brand06.png",
                  alt: "CANVAS",
                },
                link: {
                  href: "/brand/#canvas",
                },
              },
              {
                img: {
                  src: "/assets/images/about/img_brand07.png",
                  alt: "MARUNOUCHI",
                },
                link: {
                  href: "https://www.marunouchi-hotel.co.jp/",
                  blank: true,
                },
              },
              {
                img: {
                  src: "/assets/images/about/img_brand08.png",
                  alt: "ATOLL",
                },
                link: {
                  href: "https://www.atollemerald.jp/",
                  blank: true,
                },
              },
            ]}
          />
          <CBtnList
            data={[
              {
                label: "ブランド一覧",
                color: "bgBlack",
                link: {
                  href: "/brand/",
                },
              },
            ]}
          />
        </LWrap>
      </section> */}
      <NavAbout />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
