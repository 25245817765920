import * as React from "react";
import { Link, graphql } from "gatsby";
import {
  Layout, SEO, CJumbotron, CBreadCrumb, LWrap, NavAbout, CBtnList
} from "../../../components/_index"

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter
  console.log(data.markdownRemark)
  return (
    <Layout>
      <SEO title={frontmatter?.title}
        description={frontmatter?.description}
      />
      <CJumbotron
        data={{
          title: {
            ja: 'ベストレート保証'
          },
          img: {
            src: '/assets/images/common/kv.png'
          },
          imgSp: {
            src: '/assets/images/common/kv__sp.png'
          }
        }}
      />
      <CBreadCrumb data={{
        parent: [
          {
            label: '三菱地所ホテルズ＆リゾーツについて',
            path: '/about/'
          },
        ],
        current: {
          label: frontmatter?.title.includes('-') ? frontmatter?.title.split('-')[0] : frontmatter?.title
        }
      }} />
      <div className="l_sect02">
        <LWrap>
          <p className="c_sectLead">ロイヤルパークホテルズの宿泊は公式サイトからのご予約が一番お得です。<br />公式サイトからの宿泊予約における価格は、他の予約サイトで販売されている<br className="u_pc" />同一の宿泊内容・条件の宿泊予約における価格と比較して最低価格であることを保証します。 <br /><br />ロイヤルパークホテルズの会員制度 「ザ クラブ・ロイヤルパークホテルズ」 にご入会いただきますと、<br className="u_pc" />更なる割引料金にてご利用いただけます。 </p>
          <CBtnList exClass="u_mbMedium" data={[{
            label: 'ザ クラブ・ロイヤルパークホテルズ',
            link: {
              href: 'https://www.royalparkhotels.co.jp/the-club/',
              blank: true,
            },
            color: 'bgGold',
            icon: 'blank'
          }]} />
          <section className="u_mbMedium">
            <h3 className="c_headingLv3">保証対象となる条件</h3>
            <ul className="c_circleList u_mb20">
              <li>ロイヤルパークホテルズ各ホテル公式サイトからの宿泊予約であること</li>
              <li>食事等を含まない素泊まりの宿泊予約であること</li>
              <li>予約から72時間後以降にチェックインする宿泊予約であること</li>
            </ul>
            <p className="u_mb20">＜申請方法＞<br />予約から24時間以内（かつチェックインの72時間前まで）に、ご予約いただいた各ホテル公式サイトの<a href="/about/contact/">お問合せフォーム</a>より、下記必須事項を記載の上ご申請ください。ホテルは申請後48時間以内に返信します。</p>
            <p className="u_mb20">＜必須記載事項＞<br />
            ①お問合せいただいているお客様のお名前（カナ）<br />
            ②ご宿泊される方のお名前（カナ）<br />
            ③予約番号<br />
            ④宿泊日<br />
            ⑤比較対象となる予約サイト名<br />
            ⑥比較対象となる予約サイトでの宿泊プラン名<br />⑦比較対象となる予約サイトでの宿泊プランの料金
            </p>
            <p>ベストレート保証の諸条件を満たす申請が承認された場合、ロイヤルパークホテルズ各ホテル公式サイトからの宿泊予約を比較対象料金と同額とした上で、ザ クラブ・ロイヤルパークホテルズ会員のお客様には、ご滞在の客室料金に対して通常付与される2倍のポイントを付与いたします。</p>
          </section>
          <section className="u_mbMedium">
            <h3 className="c_headingLv3">保証対象外となる場合</h3>
            <ul className="c_circleList u_mb40">
              <li>ロイヤルパークホテルズ各ホテル公式サイトからの宿泊予約と比較対象予約の内容や条件が同一でない場合<br />（ホテル、部屋タイプ、眺望や階層、アメニティなど付帯サービス、宿泊人数、チェックイン・チェックアウト時間、支払方法、キャンセル・変更規定など）</li>
              <li>一般に公示されておらず、特別な手続きや資格等無しには予約できない場合<br />（特定の会員プログラム、契約法人、団体、ログインコード保有者等対象者が限定されているもの、割引コードやクーポン、またはポイントを利用・提示して予約するものなど）</li>
              <li>旅行業者による販売（手配旅行等）、旅行業者向け提示条件の他サイト販売などホテルが価格に関与できない場合<br />（旅行業者による販売や、特定の旅行業者向け提示条件の他サイト販売など）</li>
              <li>特殊な販売、予約手法である場合<br />（オークションサイトや同様の手法を利用した予約サイト、予約完了までホテル名等が提示されない予約サイト、予約申し込み後直ちに予約確定が得られないもの、予約完了前にEメールや電話での確認や認証が必要なものなど）</li>
              <li>為替レートにより変動する価格の場合</li>
            </ul>
            <CBtnList exClass="u_mbMedium" data={[{
              label: '各ホテルへのお問合せ',
              link: {
                href: '/about/contact/',
              },
            }]} />
          </section>
        </LWrap>
      </div>
      <NavAbout />
    </Layout>
  )
}

export default SubPage;

export const query = graphql`
  query ($slug: String!){
    markdownRemark(fields: {slug: {eq: $slug}}) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`