import * as React from "react";
import { graphql } from "gatsby";
import { Layout, SEO, CJumbotron, CBreadCrumb, LWrap, CPagingList, CNewsList } from "../../components/_index"
import infoChoice from "../../utils/info-choice"
import infoGet from "../../utils/info-get"

// markup
const SubPage = ({ data, pageContext }: { data: any, pageContext: { limit: number, skip: number, totalCount: number, current: number } }) => {
  const frontmatter = {
    title: "お知らせ",
    description: "三菱地所ホテルズ＆リゾーツのお知らせページです。ホテルのお知らせを掲載しています。"
  }

  return (
    <Layout>
      <SEO title={frontmatter?.title}
        description={frontmatter?.description}
      />
      <CJumbotron
        data={{
          title: {
            en: 'NEWS',
            ja: 'お知らせ'
          },
          img: {
            src: '/assets/images/news/kv.png'
          },
          imgSp: {
            src: '/assets/images/news/kv__sp.png'
          }
        }}
      />
      <CBreadCrumb data={{
        parent: [
        ],
        current: {
          label: frontmatter?.title
        }
      }} />
      <section className="l_sect u_pt80">
        <LWrap>
          <CNewsList data={infoChoice(infoGet(), '', pageContext.limit + pageContext.skip, pageContext.skip)} />
          <CPagingList totalCount={pageContext.totalCount} path="/news/" perPage={pageContext.limit} current={pageContext.current} />
        </LWrap>
      </section>
    </Layout>
  )
}

export default SubPage;
