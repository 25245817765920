import * as React from "react";
import { Link, graphql } from "gatsby";
import {
  Layout, SEO, CJumbotron, CBreadCrumb, LWrap, NavAbout, CBtnList
} from "../../../components/_index"

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter
  console.log(data.markdownRemark)
  return (
    <Layout>
      <SEO title={frontmatter?.title}
        description={frontmatter?.description}
      />
      <CJumbotron
        data={{
          title: {
            ja: 'お客様アンケート'
          },
          img: {
            src: '/assets/images/common/kv.png'
          },
          imgSp: {
            src: '/assets/images/common/kv__sp.png'
          }
        }}
      />
      <CBreadCrumb data={{
        parent: [
          {
            label: '三菱地所ホテルズ＆リゾーツについて',
            path: '/about/'
          },
        ],
        current: {
          label: frontmatter?.title.includes('-') ? frontmatter?.title.split('-')[0] : frontmatter?.title
        }
      }} />
      <div className="l_sect02">
        <LWrap>
          <p className="c_sectLead">ロイヤルパークホテルズをご利用いただきまして、誠にありがとうございます。<br />当ホテルグループのご利用に関しまして、ご意見・ご感想をお伺いするアンケートをご用意しておりますので、<br className="u_pc" />ご協力くださいますようお願い申しあげます。</p>
          <section>
            <h2 className="c_headingLv2">お客様アンケート</h2>
            <section className="u_mbMedium">
              <h3 className="c_headingLv3 u_tac">仙台ロイヤルパークホテル</h3>
              <CBtnList exClass="u_mbMedium" data={[
                {
                  label: 'ご宿泊',
                  link: {
                    href: 'https://analytics.trustyou.com/surveys/site/reviews/ty?survey_id=5e5b55ce-6055-4c09-bceb-4c258bf9ae8a',
                    blank: true,
                  },
                  color: 'bgBlack',
                  icon: 'blank'
                },
              ]} />
            </section>
            <section className="u_mbMedium">
              <h3 className="c_headingLv3 u_tac">ロイヤルパークホテル（東京・日本橋）</h3>
              <CBtnList exClass="u_mbMedium" data={[
                {
                  label: 'ご宿泊',
                  link: {
                    href: 'https://analytics.trustyou.com/surveys/site/reviews/ty?survey_id=87ae9e57-133f-4c97-9249-12e47e46d4cd',
                    blank: true,
                  },
                  color: 'bgBlack',
                  icon: 'blank'
                },
                {
                  label: 'レストラン＆バー',
                  link: {
                    href: 'https://analytics.trustyou.com/surveys/site/reviews/ty?survey_id=c2b159bf-7f06-4dc4-95a5-b4960402f76f',
                    blank: true,
                  },
                  color: 'bgBlack',
                  icon: 'blank'
                },
              ]} />
            </section>
            <section className="u_mbMedium">
              <h3 className="c_headingLv3 u_tac">横浜ロイヤルパークホテル</h3>
              <CBtnList exClass="u_mbMedium" data={[
                {
                  label: 'ご宿泊',
                  link: {
                    href: 'https://analytics.trustyou.com/surveys/site/reviews/ty?survey_id=f5621561-96da-4cb8-a325-acf79ef6260b',
                    blank: true,
                  },
                  color: 'bgBlack',
                  icon: 'blank'
                },
                {
                  label: 'レストラン＆バー',
                  link: {
                    href: 'https://analytics.trustyou.com/surveys/site/reviews/ty?survey_id=511b2b48-a0b0-4619-af24-2382a45c358d',
                    blank: true,
                  },
                  color: 'bgBlack',
                  icon: 'blank'
                },
              ]} />
            </section>
            <section className="u_mbMedium">
              <h3 className="c_headingLv3 u_tac">ザ ロイヤルパークホテル アイコニック 東京汐留</h3>
              <CBtnList exClass="u_mbMedium" data={[
                {
                  label: 'ご宿泊',
                  link: {
                    href: 'https://analytics.trustyou.com/surveys/site/reviews/ty?survey_id=1829e045-0010-4cb6-b471-a6db7311a4c3',
                    blank: true,
                  },
                  color: 'bgBlack',
                  icon: 'blank'
                },
                {
                  label: 'レストラン＆バー',
                  link: {
                    href: 'https://analytics.trustyou.com/surveys/site/reviews/ty?survey_id=9460d6b4-ccfe-447e-b696-8c9a330fddaf',
                    blank: true,
                  },
                  color: 'bgBlack',
                  icon: 'blank'
                },
              ]} />
            </section>
            <section className="u_mbMedium">
              <h3 className="c_headingLv3 u_tac">ザ ロイヤルパークホテル アイコニック 名古屋</h3>
              <CBtnList exClass="u_mbMedium" data={[
                {
                  label: 'ご宿泊',
                  link: {
                    href: 'https://analytics.trustyou.com/surveys/site/reviews/ty?survey_id=46763e1c-cda5-41f7-84e5-63700117f203',
                    blank: true,
                  },
                  color: 'bgBlack',
                  icon: 'blank'
                },
              ]} />
            </section>
            <section className="u_mbMedium">
              <h3 className="c_headingLv3 u_tac">ザ ロイヤルパークホテル アイコニック 京都</h3>
              <CBtnList exClass="u_mbMedium" data={[
                {
                  label: 'ご宿泊',
                  link: {
                    href: 'https://analytics.trustyou.com/surveys/site/reviews/ty?survey_id=5dcab9c9-e6f7-4ef1-be67-50558e5b7cd0',
                    blank: true,
                  },
                  color: 'bgBlack',
                  icon: 'blank'
                },
              ]} />
            </section>
            <section className="u_mbMedium">
              <h3 className="c_headingLv3 u_tac">ザ ロイヤルパークホテル アイコニック 大阪御堂筋</h3>
              <CBtnList exClass="u_mbMedium" data={[
                {
                  label: 'ご宿泊',
                  link: {
                    href: 'https://analytics.trustyou.com/surveys/site/reviews/ty?survey_id=7ce8acaf-ff0a-4917-89af-9d582f3b4602',
                    blank: true,
                  },
                  color: 'bgBlack',
                  icon: 'blank'
                },
                {
                  label: 'レストラン＆バー',
                  link: {
                    href: 'https://analytics.trustyou.com/surveys/site/reviews/ty?survey_id=4d96db4b-45f7-4781-9a9f-b2bdc5bc4bef',
                    blank: true,
                  },
                  color: 'bgBlack',
                  icon: 'blank'
                },
              ]} />
            </section>
            <section className="u_mbMedium">
              <h3 className="c_headingLv3 u_tac">ザ ロイヤルパークホテル 東京羽田</h3>
              <CBtnList exClass="u_mbMedium" data={[
                {
                  label: 'ご宿泊',
                  link: {
                    href: 'https://analytics.trustyou.com/surveys/site/reviews/ty?survey_id=76908325-3cb4-467a-a6b7-e9419dbc1830',
                    blank: true,
                  },
                  color: 'bgBlack',
                  icon: 'blank'
                },
                {
                  label: 'レストラン＆バー',
                  link: {
                    href: 'https://analytics.trustyou.com/surveys/site/reviews/ty?survey_id=73ae1759-54fb-47fd-a9eb-977769b79444',
                    blank: true,
                  },
                  color: 'bgBlack',
                  icon: 'blank'
                },
              ]} />
            </section>
            <section className="u_mbMedium">
              <h3 className="c_headingLv3 u_tac">ザ ロイヤルパークホテル 銀座6丁目</h3>
              <CBtnList exClass="u_mbMedium" data={[
                {
                  label: 'ご宿泊',
                  link: {
                    href: 'https://analytics.trustyou.com/surveys/site/reviews/ty?survey_id=bd6bd97d-bcd4-46dc-a11b-2ec4b6e81a98',
                    blank: true,
                  },
                  color: 'bgBlack',
                  icon: 'blank'
                },
              ]} />
            </section>
            <section className="u_mbMedium">
              <h3 className="c_headingLv3 u_tac">ザ ロイヤルパークホテル 京都三条</h3>
              <CBtnList exClass="u_mbMedium" data={[
                {
                  label: 'ご宿泊',
                  link: {
                    href: 'https://analytics.trustyou.com/surveys/site/reviews/ty?survey_id=5832c8eb-03ef-424a-b7c8-918bc0fc1a37',
                    blank: true,
                  },
                  color: 'bgBlack',
                  icon: 'blank'
                },
              ]} />
            </section>
            <section className="u_mbMedium">
              <h3 className="c_headingLv3 u_tac">ザ ロイヤルパークホテル 京都梅小路</h3>
              <CBtnList exClass="u_mbMedium" data={[
                {
                  label: 'ご宿泊',
                  link: {
                    href: 'https://analytics.trustyou.com/surveys/site/reviews/ty?survey_id=b6f97dfb-3b5e-41c1-8504-b350673d7dde',
                    blank: true,
                  },
                  color: 'bgBlack',
                  icon: 'blank'
                },
                {
                  label: 'レストラン',
                  link: {
                    href: 'https://analytics.trustyou.com/surveys/site/reviews/ty?survey_id=735bf612-8716-40b6-9cfc-3b55cc95dbad',
                    blank: true,
                  },
                  color: 'bgBlack',
                  icon: 'blank'
                },
              ]} />
            </section>
            <section className="u_mbMedium">
              <h3 className="c_headingLv3 u_tac">ザ ロイヤルパークホテル 広島リバーサイド</h3>
              <CBtnList exClass="u_mbMedium" data={[
                {
                  label: 'ご宿泊',
                  link: {
                    href: 'https://analytics.trustyou.com/surveys/site/reviews/ty?survey_id=9d54faf2-a7c5-4cc2-b94b-9f8fbf70d1c4',
                    blank: true,
                  },
                  color: 'bgBlack',
                  icon: 'blank'
                },
              ]} />
            </section>
            <section className="u_mbMedium">
              <h3 className="c_headingLv3 u_tac">ザ ロイヤルパークホテル 福岡</h3>
              <CBtnList exClass="u_mbMedium" data={[
                {
                  label: 'ご宿泊',
                  link: {
                    href: 'https://analytics.trustyou.com/surveys/site/reviews/ty?survey_id=92095c77-27c8-4101-a754-946eb5398df1',
                    blank: true,
                  },
                  color: 'bgBlack',
                  icon: 'blank'
                },
              ]} />
            </section>
            <section className="u_mbMedium">
              <h3 className="c_headingLv3 u_tac">ザ ロイヤルパーク キャンバス 札幌大通公園</h3>
              <CBtnList exClass="u_mbMedium" data={[
                {
                  label: 'ご宿泊',
                  link: {
                    href: 'https://analytics.trustyou.com/surveys/site/reviews/ty?survey_id=f7c4a2d3-e73e-493d-bf24-c3b1e89489fe',
                    blank: true,
                  },
                  color: 'bgBlack',
                  icon: 'blank'
                },
              ]} />
            </section>
            <section className="u_mbMedium">
              <h3 className="c_headingLv3 u_tac">ザ ロイヤルパーク キャンバス 銀座8</h3>
              <CBtnList exClass="u_mbMedium" data={[
                {
                  label: 'ご宿泊',
                  link: {
                    href: 'https://analytics.trustyou.com/surveys/site/reviews/ty?survey_id=e5dd84c1-7ae2-43a7-bdf8-77440dfad9fb',
                    blank: true,
                  },
                  color: 'bgBlack',
                  icon: 'blank'
                },
              ]} />
            </section>
            <section className="u_mbMedium">
              <h3 className="c_headingLv3 u_tac">ザ ロイヤルパーク キャンバス 銀座コリドー</h3>
              <CBtnList exClass="u_mbMedium" data={[
                {
                  label: 'ご宿泊',
                  link: {
                    href: 'https://analytics.trustyou.com/surveys/site/reviews/ty?survey_id=c96277a4-7e08-4802-b79b-f00a4b33856e',
                    blank: true,
                  },
                  color: 'bgBlack',
                  icon: 'blank'
                },
                {
                  label: 'レストラン＆バー',
                  link: {
                    href: 'https://analytics.trustyou.com/surveys/site/reviews/ty?survey_id=f50765d0-77e7-4d35-914a-0aacffb3e308',
                    blank: true,
                  },
                  color: 'bgBlack',
                  icon: 'blank'
                },
              ]} />
            </section>
            <section className="u_mbMedium">
              <h3 className="c_headingLv3 u_tac">ザ ロイヤルパーク キャンバス 名古屋</h3>
              <CBtnList exClass="u_mbMedium" data={[
                {
                  label: 'ご宿泊',
                  link: {
                    href: 'https://analytics.trustyou.com/surveys/site/reviews/ty?survey_id=3a839f2f-76c5-44b6-9f01-cb24b825d6fe',
                    blank: true,
                  },
                  color: 'bgBlack',
                  icon: 'blank'
                },
              ]} />
            </section>
            <section className="u_mbMedium">
              <h3 className="c_headingLv3 u_tac">ザ ロイヤルパーク キャンバス 京都二条</h3>
              <CBtnList exClass="u_mbMedium" data={[
                {
                  label: 'ご宿泊',
                  link: {
                    href: 'https://analytics.trustyou.com/surveys/site/reviews/ty?survey_id=fd7a44c4-e286-4a11-a219-89411458a645',
                    blank: true,
                  },
                  color: 'bgBlack',
                  icon: 'blank'
                },
              ]} />
            </section>
            <section className="u_mbMedium">
              <h3 className="c_headingLv3 u_tac">ザ ロイヤルパーク キャンバス 大阪北浜</h3>
              <CBtnList exClass="u_mbMedium" data={[
                {
                  label: 'ご宿泊',
                  link: {
                    href: 'https://analytics.trustyou.com/surveys/site/reviews/ty?survey_id=945500d3-7a6f-4cc5-90d2-dfa576f83b5a',
                    blank: true,
                  },
                  color: 'bgBlack',
                  icon: 'blank'
                },
              ]} />
            </section>
            <section className="u_mbMedium">
              <h3 className="c_headingLv3 u_tac">ザ ロイヤルパーク キャンバス 神戸三宮</h3>
              <CBtnList exClass="u_mbMedium" data={[
                {
                  label: 'ご宿泊',
                  link: {
                    href: 'https://analytics.trustyou.com/surveys/site/reviews/ty?survey_id=3536c4d1-3241-4c5f-86a6-56715eb51433',
                    blank: true,
                  },
                  color: 'bgBlack',
                  icon: 'blank'
                },
              ]} />
            </section>
            <section className="u_mbMedium">
              <h3 className="c_headingLv3 u_tac">ザ ロイヤルパーク キャンバス 福岡中洲</h3>
              <CBtnList exClass="u_mbMedium" data={[
                {
                  label: 'ご宿泊',
                  link: {
                    href: 'https://analytics.trustyou.com/surveys/site/reviews/ty?survey_id=f3f89d08-2136-4e77-a94a-0dd52770ae41',
                    blank: true,
                  },
                  color: 'bgBlack',
                  icon: 'blank'
                },
              ]} />
            </section>
          </section>
        </LWrap>
      </div>
      <NavAbout />
    </Layout>
  )
}

export default SubPage;

export const query = graphql`
  query ($slug: String!){
    markdownRemark(fields: {slug: {eq: $slug}}) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`