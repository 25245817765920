import * as React from "react";
import { graphql } from "gatsby";
import {
  Layout,
  SEO,
  LWrap,
  CSectTitle,
  CPostCardSlider,
  CBtnList,
  CKv,
  CAnchorNav,
  LFullService,
  LIconic,
  LThe,
  LCanvas,
  CBenefitCard,
  CTab,
  CTabItem,
  CNewsList,
  LBgsect,
  CContentBox,
  CBtnList02,
  LAtoll,
} from "../components/_index";
import infoChoice from "../utils/info-choice";
import infoGet from "../utils/info-get";
import releaseChoice from "../utils/release-choice";
import releaseGet from "../utils/release-get";
import pickupChoice from "../utils/pickup-choice";
import pickupGet from "../utils/pickup-get";
import { useMobile } from "../utils/use-series";

// markup
const IndexPage = () => {
  const isSp = useMobile();
  return (
    <Layout isTop={true}>
      <SEO ogtype={"website"} />
      <CKv
        video={{
          src: "/assets/movie/kv.mp4",
        }}
      />
      <div className="l_sect04 u_bgGray u_sp">
        <LWrap>
          <CAnchorNav
            colSp={1}
            data={[
              {
                title: "ホテルを探す",
                link: {
                  href: "#search",
                },
              },
            ]}
          />
        </LWrap>
      </div>
      <section className="l_sect u_bgMarbleWhite">
        <LWrap>
          <CSectTitle
            title={{
              ja: "ザ クラブ・ロイヤルパークホテルズのご案内",
              en: "MEMBERSHIP",
            }}
          />
          <p className="c_sectLead">
            ザ
            クラブ・ロイヤルパークホテルズは、全国のロイヤルパークホテルズでご利用いただける
            <em className="u_fwb">入会金・年会費無料の会員制度</em>です。
            <br />
            ポイント特典やお得な割引などのさまざまなサービスがございます。
          </p>
          <CBenefitCard
            data={[
              {
                label: (
                  <>
                    Benefit <em>01</em>
                  </>
                ),
                img: {
                  src: "/assets/images/common/icon_benefit.svg",
                  alt: "",
                },
                title: (
                  <>
                    <em>お得</em>な<br />
                    宿泊プラン
                  </>
                ),
              },
              {
                label: (
                  <>
                    Benefit <em>02</em>
                  </>
                ),
                img: {
                  src: "/assets/images/common/icon_benefit02.svg",
                  alt: "",
                },
                title: (
                  <>
                    レストラン・<sup>※</sup>
                    <br />
                    ショップ<em>割引</em>
                  </>
                ),
              },
              {
                label: (
                  <>
                    Benefit <em>03</em>
                  </>
                ),
                img: {
                  src: "/assets/images/common/icon_benefit03.svg",
                  alt: "",
                },
                title: (
                  <>
                    <em>ポイント</em>
                    <br />
                    特典
                  </>
                ),
              },
              {
                label: (
                  <>
                    Benefit <em>04</em>
                  </>
                ),
                img: {
                  src: "/assets/images/common/icon_benefit04.svg",
                  alt: "",
                },
                title: (
                  <>
                    <em>会員ステージ</em>
                    <br />
                    制度
                  </>
                ),
              },
              {
                label: (
                  <>
                    Benefit <em>05</em>
                  </>
                ),
                img: {
                  src: "/assets/images/common/icon_benefit05.svg",
                  alt: "",
                },
                title: (
                  <>
                    <em>アニバーサリー</em>
                    <br />
                    特典
                  </>
                ),
              },
              {
                label: (
                  <>
                    Benefit <em>06</em>
                  </>
                ),
                img: {
                  src: "/assets/images/common/icon_benefit06.svg",
                  alt: "",
                },
                title: (
                  <>
                    <em>優待施設</em>
                    <br />
                    での特典
                  </>
                ),
              },
            ]}
          />
          <ul className="c_noteList">
            <li>対象のレストラン・ショップは会員サイトをご確認ください。</li>
          </ul>
          <CBtnList
            data={[
              {
                label: "会員サイト",
                link: {
                  href: "https://www.royalparkhotels.co.jp/the-club/",
                  blank: true,
                },
                color: "bgGold",
                icon: "blank",
              },
            ]}
          />
        </LWrap>
      </section>
      <section className="l_sect u_bgGray">
        <LWrap>
          <CSectTitle title={{ ja: "ピックアップ", en: "PICK UP" }} />
          <CPostCardSlider data={pickupChoice(pickupGet(), "", 5)} />
          <CBtnList
            exClass="u_mb100 u_mb80"
            data={[
              {
                label: "ピックアップ一覧",
                color: "bgBlack",
                link: {
                  href: "/pickup/",
                },
              },
            ]}
          />
          <CContentBox color="white">
            <CTab defaultKey={0}>
              <CTabItem
                title={{
                  ja: "ニュースリリース",
                  en: "RELEASE",
                }}
                tabKey={0}
              >
                <CNewsList data={releaseChoice(releaseGet(), 5)} />
                <CBtnList
                  data={[
                    {
                      label: "ニュースリリース一覧",
                      color: "bgBlack",
                      link: {
                        href: "/release/",
                      },
                    },
                  ]}
                />
              </CTabItem>
              <CTabItem
                title={{
                  ja: "お知らせ",
                  en: "NEWS",
                }}
                tabKey={1}
              >
                <CNewsList data={infoChoice(infoGet(), "", 5)} />
                <CBtnList
                  data={[
                    {
                      label: "お知らせ覧一覧",
                      color: "bgBlack",
                      link: {
                        href: "/news/",
                      },
                    },
                  ]}
                />
              </CTabItem>
            </CTab>
          </CContentBox>
        </LWrap>
      </section>
      <section className="l_sect" id="search">
        <LWrap>
          <CSectTitle title={{ ja: "ロイヤルパークホテルズ", en: "FIND A HOTEL" }} />
       
          <CBtnList
            exClass="u_mb100 u_mb80"
            data={[
              {
                label: "エリアから探す",
                link: {
                  href: "/location/",
                },
              },
              {
                label: "ブランドから探す",
                link: {
                  href: "/brand/",
                },
              },
              {
                label: "過ごし方から探す",
                link: {
                  href: "/experience/",
                },
              },
            ]}
          />
        </LWrap>
      </section>
      {!isSp && (
        <>
          <LFullService>
            <LWrap exClass="u_pt100 u_pb80 u_tac u_colorWhite">
              <p className="u_tac">
                洗練された、心地よい空間とホスピタリティ。さまざまなタイプの客室や多彩なレストラン、
                <br />
                披露宴などを行うバンケットを備えたフルサービス型のホテルです。ワンランク上のホテルステイをご満喫ください。
              </p>
            </LWrap>
          </LFullService>
          <LIconic />
          <LThe />
          <LCanvas />
          <LAtoll />
        </>
      )}

      <LBgsect
        img={{
          src: "/assets/images/home/bg_btn.png",
        }}
        imgSp={{
          src: "/assets/images/home/bg_btn__sp.png",
        }}
      >
        <CBtnList02
          data={[
            {
              label: {
                ja: "会社概要",
                en: "CORPORATE",
              },
              link: {
                href: "/about/",
              },
            },
            {
              label: {
                ja: "採用情報",
                en: "RECRUIT",
              },
              link: {
                href: "/recruit/",
              },
            },
          ]}
        />
      </LBgsect>
    </Layout>
  );
};

export default IndexPage;
